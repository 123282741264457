const navigation = {
  position: 'fixed',
  top: '50vh',
  right: 0,
  zIndex: 2,
  fontSize: '5rem',
  background: '#FFFFFF90',
  height: '5rem',
  width: '5rem',
  borderRadius: '5rem',
  lineHeight: '5rem',
  color: 'black',
  textAlign: 'center',
  margin: 0
};

const styles = {
  inlineImage: { width: '100%' },
  backgroundImage: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    top: 0,
    left: 0,
    zIndex: 0,
    mixBlendMode: 'hard-light',
    opacity: 0.5
  },
  navigationPrev: {
    ...navigation,
    left: '-1rem'
  },
  navigationNext: {
    ...navigation,
    right: '-1rem'
  },
  blackletter: {
    color: '#dd0000',
    fontFamily: 'FetteFraktur',
    fontSize: '100rem',
    mixBlendMode: 'multiply',
    zIndex: 0,
    maxWidth: '100vw',
    position: 'fixed',
    top: '0rem',
    left: 0,
    lineHeight: '100vh'
  },
  blackletterInline: {
    color: '#127885',
    opacity: 0.8,
    fontFamily: 'FetteFraktur',
    fontSize: '4rem',
    mixBlendMode: 'multiply',
    top: '1.7rem',
    marginRight: '1rem',
    position: 'relative'
  },
  testBerkeley: {
    color: 'white',
    fontFamily: 'Berkeley',
    fontSize: '3rem'
  },
  copyBasier: {
    position: 'relative',
    padding: '2rem',
    zIndex: 1,
    fontFamily: 'BasierRegular',
    fontSize: '1.5rem',
    lineHeight: '2.8rem'
  },
  bodyCopy: {
    position: 'relative',
    color: 'white',
    fontFamily: 'Berkeley',
    zIndex: 1,
    fontSize: '1.5rem',
    lineHeight: '3rem',
    fontWeight: 900
  },
  introduction: {
    color: 'white',
    fontFamily: 'Berkeley',
    zIndex: 1,
    fontSize: '1.5rem',
    lineHeight: '3rem',
    textShadow: '1px 1px #000000'
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    background: 'black',
    height: '100vh',
    width: '100vw',
    overflow: 'scroll'
  },
  container: {
    color: 'white',
    width: '40rem',
    lineHeight: '2rem',
    margin: 'auto',
    padding: '5rem'
  },
  navbar: {
    position: 'fixed',
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '3rem',
    width: '100vw',
    alignItems: 'center',
    height: '3rem',
    opacity: 1.0,
    zIndex: 2,
    background: 'linear-gradient(181deg, #4e4b4b, #9e9e9e)'
  },
  pageIndicator: {
    textAlign: 'center',
    width: '4rem',
    margin: '0 .25rem 0 .25rem',
    fontSize: '1rem'
  },
  pageIndicatorCurrent: {
    background: 'white',
    color: 'black',
    textAlign: 'center',
    width: '4rem',
    margin: '0 .25rem 0 .25rem',
    borderRadius: '4rem',
    fontSize: '1rem'
  },
  link: {
    color: '#898989',
    textDecoration: 'underline'
  }
};
export default styles;
