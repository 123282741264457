//import InlineImage from 'components/InlineImage.js';
import React from 'react';
import styles from './styles.js';
import PhotoSwipe from '../components/PhotoSwipe';

const Page05 = ({ assetUrl }) => (
  <React.Fragment>
    <div style={styles.copyBasier}>
      <p>
        We lie and wait as the shadows of the buildings creep over our bodies
        like leeches, squeezing out our screams with wet fingers. I lay here for
        hours not knowing what happened. How did we survive? I still can't
        understand. They are the ones who should have died, not me. The one I
        love is still alive, still fighting and dying. We must have both won,
        that’s what I have been told. But what does it feel like? What does it
        mean? Does he think of me while I am trapped in this darkness? What does
        he think when he closes his eyes at night and imagines he can smell the
        smell of our house, of our kitchen, of my mother's cooking?
      </p>
      <p>And he does. He imagines. I know he does.</p>
      <p>
        I can see the sky through the hole in the ceiling, even though it is
        night. I've been here for hours now, days at least. This was all that
        was left.
      </p>
      <PhotoSwipe id={['000029.3820695810', '000035.3947768215']} />
    </div>

    <div
      style={{
        ...styles.backgroundImage,
        backgroundImage: `url("${assetUrl}/000029.3820695810.png")`
      }}
    />
  </React.Fragment>
);
export default Page05;
