import Home from 'pages/Home';
import Page00 from 'pages/Page00';
import Page01 from 'pages/Page01';
import Page02 from 'pages/Page02';
import Page03 from 'pages/Page03';
import Page04 from 'pages/Page04';
import Page05 from 'pages/Page05';
import Page06 from 'pages/Page06';
import Page07 from 'pages/Page07';
import Page08 from 'pages/Page08';
import Page09 from 'pages/Page09';
import Page10 from 'pages/Page10';
import Page11 from 'pages/Page11';

const assetUrl = 'https://assets.intrepidpixel.org/met-averse';

const Pages = [
  <Page00 assetUrl={assetUrl} />,
  <Page01 assetUrl={assetUrl} />,
  <Page02 assetUrl={assetUrl} />,
  <Page03 assetUrl={assetUrl} />,
  <Page04 assetUrl={assetUrl} />,
  <Page05 assetUrl={assetUrl} />,
  <Page06 assetUrl={assetUrl} />,
  <Page07 assetUrl={assetUrl} />,
  <Page08 assetUrl={assetUrl} />,
  <Page09 assetUrl={assetUrl} />,
  <Page10 assetUrl={assetUrl} />,
  <Page11 assetUrl={assetUrl} />
];

export { Home, Pages, assetUrl };
