import React from 'react';
import styles from './styles.js';
import PhotoSwipe from '../components/PhotoSwipe';

const Page10 = ({ assetUrl }) => (
  <React.Fragment>
    <div style={styles.copyBasier}>
      <p>
        If a person were alive and in the image or alive and being recorded
        digitally then we might be able to see what was in their eyes and what
        they saw, that the image captured that which they could not be aware of
        at that moment.
      </p>
      <p>
        <PhotoSwipe id={'000010.360833797'} />
      </p>
    </div>

    <div
      style={{
        ...styles.backgroundImage,
        backgroundImage: `url("${assetUrl}/000010.360833797.png")`
      }}
    />
  </React.Fragment>
);
export default Page10;
