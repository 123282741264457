import React from 'react';
import styles from './styles';
import PhotoSwipe from '../components/PhotoSwipe';

const Page02 = ({ assetUrl }) => (
  <React.Fragment>
    <div style={styles.copyBasier}>
      <p>
        "We're safe now," says the big girl. She sits beside him and lays a soft
        hand on his shoulder "Come on buddy I'll take you home. Wherever that
        is."
      </p>
      <p>"Is it still here?" asks the girl from the other side.</p>
      <p>
        "I dunno," he says. "I never heard of a place that doesn't have a war."
      </p>
      <p>
        "Then we are safe, " she says. "Maybe we will get some time to find
        out."
      </p>
      <p>"If it's still here you'll have to tell me how to get home."</p>
      <p>"We'll find a cab." she says.</p>
      <p>"Cab? Where do you think we are, we are the road?"</p>
      <p>
        "There are lots of cabs, there must be a hundred around, if not more.
        Some of them might still work.
      </p>
      <PhotoSwipe id={'000027.1584152628'} />
    </div>
    <div
      style={{
        ...styles.backgroundImage,
        backgroundImage: `url("${assetUrl}/000027.1584152628.png")`
      }}
    />
  </React.Fragment>
);
export default Page02;
