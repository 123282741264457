import React from 'react';
import styles from './styles.js';

const Page01 = ({ assetUrl }) => (
  <React.Fragment>
    <div
      style={{
        ...styles.copyBasier,
        padding: '2rem',
        backgroundColor: '#000000b3'
      }}
    >
      <p>MET-AVERSE</p>
      <p>
        <i>Metaverse</i> (which may mean meta-game or meta-verse or metaverse,
        etc.) is when one engages in discourse about discourse about discourse
        about discourse about… (see, “Metaverse,”) where one moves one’s eyes to
        move one’s mouse to move one’s pointer to move the chat window to move
        the keyboard to move the computer to move the light switch to move the
        light bulb to move the light from the light bulb to move the world’s
        resources to create the world.
      </p>
      <p>That is the metaverse.</p>
    </div>
    <div
      style={{
        ...styles.backgroundImage,
        backgroundImage: `url("${assetUrl}/000003.1131400206.png")`
      }}
    />
  </React.Fragment>
);
export default Page01;
