import React from 'react';
import styles from './styles.js';
import PhotoSwipe from '../components/PhotoSwipe';

const Page06 = ({ assetUrl }) => (
  <React.Fragment>
    <div style={styles.copyBasier}>
      <p>_M_ _i-hong_ _C_ _athedral_ _,_ _L_ _eaving_ _Hong_ _Kong_</p>
      <p>
        <b>1</b>
      </p>
      <p>
        <b>I'M STANDING</b> outside the airport, a man behind the glass tells me
        I have to hurry, the immigration officer is about to close the office
        for the day. It's not yet nine in the morning, I'm not even supposed to
        be here yet.
      </p>
      <p>
        He's not very nice, the immigration officer. But then again, I'm the one
        who told him where I wanted to go.
      </p>
      <p>
        He tells me I have to be back in five days and to have a valid passport,
        even if I have one with me already.
      </p>
      <p>
        I've been here for three days already, I shouldn't even have this one
        left, I'm told to keep it for the next five days. But the thing is, I'm
        not supposed to be there five days from now, not yet, my time in China
        has already been extended twice. And this time when I go through the
        airport, this time when they check my passport and try to determine if
        I've ever been deported, they won't find me here. The truth is I've
        never been there.
      </p>
      <p>
        I've been here twice, but only for the night, I was told not to come
        back. They told me that Hong Kong was only supposed to be a stop, that
        once I was here, I would have to move on, they said that if I stayed,
        the authorities would take me. I asked why they told me to go in the
        first place and they told me to go in the first place because they knew
        that I was going to make it, and that once I was there, they couldn't
        know what I would do next.
      </p>
      <p>
        <PhotoSwipe id={'000010.628126893'} />
      </p>
    </div>

    <div
      style={{
        ...styles.backgroundImage,
        backgroundImage: `url("${assetUrl}/000010.628126893.png")`
      }}
    />
  </React.Fragment>
);
export default Page06;
