import React from 'react';
import styles from './styles.js';

const Page01 = ({ assetUrl }) => (
  <React.Fragment>
    <div style={styles.bodyCopy}>
      :: MET-AVERSE (A Generative Zine)
      <br />
      :: A FERAL RESEARCH PROJECT
      <br />
      :: PUBLISHED BY INTREPID PIXEL PRESS
      <br />
      <br />
      <div style={{ fontSize: '1.2rem', lineHeight: '2rem' }}>
        This vignette narrative was produced in collaboration with the
        algorithms{' '}
        <a style={styles.link} href="https://6b.eleuther.ai/">
          GPT-J
        </a>{' '}
        (text) and{' '}
        <a
          style={styles.link}
          href="https://en.wikipedia.org/wiki/Stable_Diffusion"
        >
          Stable Diffusion
        </a>{' '}
        (images). It was originally intended for publication as an app to
        kickstart a series of digital zines, but this project was rejected from
        the app store by Apple because it contained "too much text, and also
        still images." We live in strange times.
        <br /> <br />
        The content is based on a text that I prepared for another, different
        and as-of-yet unpublished project. I ran this text through both GPT-J
        and as a prompt to SD. Each "article" in this collection is the result
        of that output.
        <br /> <br /> I was struck at how well failures in generation echoed the
        central themes of the text, with the software often hallucinating a more
        haunting and often much funnier version of what I originally had in
        mind.
        <br />
        <br />
        This is the ghost in the machine of that other project, seen through a
        mirror darkly.
        <br />
        <br />
        What happens when we call a void function and the void function returns
        something after all?
      </div>
      <br />
      :: LAUSANNE, OCTOBER 2023
    </div>
    <div
      style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        backgroundImage: `url("${assetUrl}/000004.836953867.png")`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        top: 0,
        left: 0,
        zIndex: 0,
        mixBlendMode: 'hard-light',
        opacity: 0.5
      }}
    />
    <div style={styles.blackletter}>met-averse</div>
  </React.Fragment>
);
export default Page01;
