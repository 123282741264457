import React from 'react';
import 'photoswipe/dist/photoswipe.css';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { assetUrl } from 'pages';

const PhotoSwipe = ({ id, width = 768, height = 512 }) => {
  const items = Array.isArray(id) ? id : [id];
  return (
    <Gallery id={`gallery_${id}`}>
      {items.map((id) => {
        return (
          <Item
            key={id}
            original={`${assetUrl}/${id}.png`}
            thumbnail={`${assetUrl}/${id}.png`}
            width={width}
            height={height}
          >
            {({ ref, open }) => (
              <img
                style={{ width: '100%' }}
                alt={id}
                ref={ref}
                onClick={open}
                src={`${assetUrl}/${id}.png`}
              />
            )}
          </Item>
        );
      })}
    </Gallery>
  );
};

export default PhotoSwipe;
