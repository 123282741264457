import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useLayoutEffect
} from 'react';
import defaultStyles from '../pages/styles.js';
import { Link, useNavigate } from 'react-router-dom';
import { GrPrevious, GrNext } from 'react-icons/gr';
import Mousetrap from 'mousetrap';

const breakPoint = 1000;
/*
let touchStart = 0;
let touchEnd = 0;
*/

const Navigation = ({ current, previous, next, max }) => {
  const paginationRef = useRef();
  const currentPageRef = useRef();
  const [minimized, setMinimized] = useState(false);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    const left =
      currentPageRef.current && paginationRef.current
        ? currentPageRef.current.offsetLeft - paginationRef.current.offsetLeft
        : 0;
    paginationRef.current.scrollTo({
      top: 0,
      left,
      behavior: 'smooth'
    });
  });

  useEffect(() => {
    //setHeight(window.innerHeight);
    setWidth(window.innerWidth);
    window.addEventListener(
      'resize',
      () => {
        //setHeight(window.innerHeight);
        setWidth(window.innerWidth);
      },
      false
    );
  }, []);

  useEffect(() => {
    if (width < breakPoint) {
      setMinimized(true);
    } else {
      setMinimized(false);
    }
  }, [width]);

  const navigate = useNavigate();
  const pageTo = useCallback(
    (page) => {
      if (page !== null && page >= 0 && page < max) navigate(`/${page}`);
    },
    [navigate, max]
  );

  const styles = {
    ...defaultStyles,
    pageIndicators: minimized
      ? {
          display: 'none'
        }
      : { display: 'flex', maxWidth: '40rem', overflow: 'scroll' }
  };

  useEffect(() => {
    const checkDirection = () => {
      console.log('Swipe disabled');
      /*
      const distance = Math.abs(touchEnd - touchStart);
      if (distance > document.body.clientWidth / 3) {
        if (touchEnd < touchStart) pageTo(next);
        if (touchEnd > touchStart) pageTo(previous);
      }
      */
    };
    const onStart = (e) => {
      //touchStart = e.changedTouches[0].screenX;
    };
    const onEnd = (e) => {
      //touchEnd = e.changedTouches[0].screenX;
      checkDirection();
    };
    window.addEventListener('touchstart', onStart);
    window.addEventListener('touchend', onEnd);
    Mousetrap.bind('right', () => pageTo(next), 'keyup');
    Mousetrap.bind('left', () => pageTo(previous), 'keyup');
    Mousetrap.bind('down', () => pageTo(next), 'keyup');
    Mousetrap.bind('up', () => pageTo(previous), 'keyup');
    return () => {
      window.removeEventListener('touchstart', onStart);
      window.removeEventListener('touchend', onEnd);
      Mousetrap.unbind('right', () => pageTo(next), 'keyup');
      Mousetrap.unbind('left', () => pageTo(previous), 'keyup');
      Mousetrap.unbind('down', () => pageTo(next), 'keyup');
      Mousetrap.unbind('up', () => pageTo(previous), 'keyup');
    };
  }, [pageTo, previous, next]);

  const pagination = current === 0 ? 'i.' : `${current} / ${max - 1}`;
  return (
    <div id="navigation">
      <div
        style={{
          ...styles.navbar,
          backgroundBlendMode: 'multiply'
        }}
      >
        <div style={{ flexGrow: 1 }} />
        <Link to={`/`}>
          <div style={styles.blackletterInline}>met-averse</div>
        </Link>
        <div ref={paginationRef} style={styles.pageIndicators}>
          {[...Array(max - 1)].map((_item, idx) => (
            <div key={idx + 1}>
              {(current !== idx + 1 && (
                <div style={styles.pageIndicator}>
                  <Link style={{ color: '#ffffff3d' }} to={`/${idx + 1}`}>
                    {String(idx + 1).padStart(idx + 1 < 10 ? 2 : 0, '0')}
                  </Link>
                </div>
              )) || (
                <div ref={currentPageRef} style={styles.pageIndicatorCurrent}>
                  {String(idx + 1).padStart(idx + 1 < 10 ? 2 : 0, '0')}
                </div>
              )}
            </div>
          ))}
        </div>
        <div style={{ flexGrow: 1 }} />
        {minimized && (
          <div
            style={{
              ...styles.pageIndicator,
              color: '#ffffff3d',
              fontSize: '1rem',
              position: 'fixed',
              right: '.5rem'
            }}
          >
            {pagination}
          </div>
        )}
      </div>
      {(previous !== null && (
        <Link to={`/${previous}`}>
          <div style={styles.navigationPrev}>
            <GrPrevious />
          </div>
        </Link>
      )) || (
        <div style={{ ...styles.navigationPrev, opacity: 0.25 }}>
          <GrPrevious />
        </div>
      )}
      {(next < max && (
        <Link to={`/${next}`}>
          <div style={styles.navigationNext}>
            <GrNext />
          </div>
        </Link>
      )) || (
        <div style={{ ...styles.navigationNext, opacity: 0.25 }}>
          <GrNext />
        </div>
      )}
    </div>
  );
};
export default Navigation;
