import React from 'react';
import styles from './styles.js';
import PhotoSwipe from '../components/PhotoSwipe';

const Page07 = ({ assetUrl }) => (
  <React.Fragment>
    <div style={styles.copyBasier}>
      <p>
        So when I come back and everything is the same, I feel empty and that’s
        why I go back.
      </p>
      <p>
        It's like someone is being erased, and you don't know. Someone you've
        known forever, but have you known it? And if I'm being erased, is it
        because I was the one that did it? That's how I feel, like someone's
        been erased from my life, and it feels like a horrible thing. A thing
        you don't wish on someone, but sometimes it's the only way.
      </p>
      <PhotoSwipe id={'000035.3816656307'} />
    </div>

    <div
      style={{
        ...styles.backgroundImage,
        backgroundImage: `url("${assetUrl}/000035.3816656307.png")`
      }}
    />
  </React.Fragment>
);
export default Page07;
