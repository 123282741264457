import React from 'react';
import styles from './styles.js';
import PhotoSwipe from '../components/PhotoSwipe';

const Page11 = ({ assetUrl }) => (
  <React.Fragment>
    <div style={styles.copyBasier}>
      <p>
        If we were alive, then we would know what we knew or did not know, what
        we meant or did not mean.
      </p>
      <p>It was just a thought of mine for a day.</p>
      <p>
        A: The problem is that even though we forget the specifics of what we
        witnessed, the camera remembers it for us.
      </p>
      <PhotoSwipe id={'000010.2460264137'} />
    </div>
    <div
      style={{
        ...styles.backgroundImage,
        backgroundImage: `url("${assetUrl}/000010.2460264137.png")`
      }}
    />
  </React.Fragment>
);
export default Page11;
