import React, { useEffect, useRef } from 'react';

import { useParams } from 'react-router-dom';
import Navigation from 'components/Navigation';
import styles from './styles.js';
import { Pages } from 'pages';

const Home = () => {
  const { id } = useParams();
  const content = useRef();
  let currentId = parseInt(id ? id : 0, 10);
  currentId =
    currentId > Pages.length ? Pages.length : currentId < 0 ? 0 : currentId;
  const nextId = currentId + 1 < Pages.length ? currentId + 1 : Pages.length;
  const prevId = currentId - 1 >= 0 ? currentId - 1 : null;

  useEffect(() => {
    content.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto'
    });
  }, [currentId]);

  return (
    <div style={{ height: '100vh', position: 'fixed' }}>
      <Navigation
        current={currentId}
        max={Pages.length}
        next={nextId}
        previous={prevId}
      />
      <div ref={content} style={styles.wrapper}>
        <div style={styles.container}>{Pages[currentId]}</div>
      </div>
    </div>
  );
};
export default Home;
