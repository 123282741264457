import React from 'react';
import styles from './styles.js';
import PhotoSwipe from '../components/PhotoSwipe';

const Page08 = ({ assetUrl }) => (
  <React.Fragment>
    <div style={styles.copyBasier}>
      <p>
        I don't mean to sound so melancholy. It's just that this was the fourth
        person, and it makes me wonder what else is being erased. It made me
        wonder if I'm the one being erased, but not from someone's life. It's
        more of my life that's being taken away.
      </p>
      <p>
        Or erased. The way he's left me. So much so I feel like I'm
        disappearing. It makes me feel, I don't know, helpless. Like I've had it
        with trying. Like I could've never reached him. That he left me in the
        dark, in the cold, but in the dark I felt nothing. Not even cold. No,
        not even there.
      </p>
      <p>
        Just the one there, not even there, or at least it felt like that. My
        hands felt like they couldn't move. My feet felt frozen in place. I
        didn't know where I was, but I was cold. I didn't feel anything.
      </p>
      <p>
        And that's how it had felt when I woke up that morning, in that cold
        room, in that bed. I had felt nothing, or no one. I had felt nothing.
      </p>
      <p>
        It took me three days to get the courage to try and reach him. What if
        it didn't work, what if he never answered, or what if it was a wrong
        number, what if this wasn't him, what if he wasn't there?
      </p>
      <p>
        I didn't know what to say if it was a wrong number, so I hung up on it.
      </p>
      <p>
        When I met K., it was like I always hoped to meet a friend I would meet
        randomly, by accident, and suddenly I felt so happy when I thought about
        her, that I could not hold it in. It was like a love-hate story for all
        of us. She told me she would fall in love with me immediately. I had no
        idea what to think.
      </p>
      <p>
        In another world, she told me I looked exactly the way she imagined.
      </p>
      <p>
        She told me it’d been a few years since she’d felt in love and I made
        her smile. She told me I reminded her of someone she’d once loved a long
        time ago. She even remembered a part of that person’s name. I didn’t
        remember how to tell her my name. When she asked what it was, I told her
        the name of the person who gave me that nickname, a person I did not
        know.
      </p>
      <p>“Yes, you’re right,” she said. “You look like this person.”</p>
      <p>She said she once wrote a book called “The Day I Met This Person.”</p>
      <p>
        I told her I had never read anything like that. I didn’t know it even
        existed, yet I felt like it was my first book.
      </p>
      <p>She looked at me and made a small, happy face.</p>
      <p>“I’m such an idiot. I just forgot what I had to say!”</p>
      <p>
        She put her hands together as if she were at prayer and looked at the
        ceiling.
      </p>
      <p>“I thought it would be different this time,” she said. </p>

      <PhotoSwipe id={'000029.4076957221'} />
    </div>

    <div
      style={{
        ...styles.backgroundImage,
        backgroundImage: `url("${assetUrl}/000029.4076957221.png")`
      }}
    />
  </React.Fragment>
);
export default Page08;
