import React from 'react';
import styles from './styles.js';
import PhotoSwipe from '../components/PhotoSwipe';

const Page03 = ({ assetUrl }) => (
  <React.Fragment>
    <div style={styles.copyBasier}>
      <p>
        "Thing is, even if you were to start to think about what all these
        things mean, which we don’t.
      </p>
      <p>
        We’re not even entirely sure why we’re here, or what it all means. We’re
        just in this place and it’s not good.
      </p>
      <p>This story is a lie, and I wish it were anything else.</p>
      <p>
        I thought you’d notice if I didn’t. I thought that if I didn’t let you
        know that I’m leaving, that it would hurt less. And even now, when I
        should be letting you know, I’m not telling you. I’m going to leave you
        and I’m not going to look back. Because I’m going to a place where I can
        forget you, forget that you came first.
      </p>
      <p>The way I want to.</p>
      <p>But I’m not.</p>
      <p>
        It’s like every other part of my life has been like this for the past
        week. Every other part is just there, existing and not existing at the
        same time. It’s not a decision, really, it’s not. I can’t explain how I
        got here and how it feels.
      </p>
      <p>
        The illusion of self, lost in this kind of thinking, it’s not so much a
        fantasy as a coping mechanism. How else can you go about your life when
        you realize that, actually, there is nothing but numbers and patterns
        for anything you’re looking at? So, even if you were to think about
        someone else, or if you were to read about one of the major events in
        world history, you couldn’t say what any of it looks like to them, you
        could only look at it from your perspective and hope they’re being as
        nice to you as you are to them.
      </p>
      <p>
        No one ever really looks at another person, do they? I mean, the closest
        thing to mutual surveillance we get is a selfie. But that’s not the same
        thing as actually trying to figure out how the other is actually
        thinking. Because if you do, you realize that it’s really only about the
        image, not about the person
      </p>
      <PhotoSwipe id={'000010.360833797'} />
    </div>
    <div
      style={{
        ...styles.backgroundImage,
        backgroundImage: `url("${assetUrl}/000010.360833797.png")`
      }}
    />
  </React.Fragment>
);
export default Page03;
