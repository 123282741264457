import React from 'react';
import styles from './styles.js';
import PhotoSwipe from '../components/PhotoSwipe';

const Page09 = ({ assetUrl }) => (
  <React.Fragment>
    <div style={styles.copyBasier}>
      <p>This section is all about loss.</p>
      <p>We'll use it to prepare ourselves</p>
      <p>for the rest of the collection,</p>
      <p>but I have to say,</p>
      <p>I haven't cried since that day,</p>
      <p>that terrible day.</p>
      <p>This is as far as I can go.</p>
      <p>My eyes are dry.</p>
      <p>My body is numb.</p>
      <p>But all of this is useless</p>
      <p>because all I can do is</p>
      <p>hold my own loss in.</p>
      <p>You see,</p>
      <p>there are moments in our lives</p>
      <p>that we lose more</p>
      <p>than we can bear.</p>
      <p>There are moments in our lives</p>
      <p>when we lose so much that it changes us.</p>
      <p>So many of us are living</p>
      <p>after having been in these</p>
      <p>places and knowing</p>
      <p>what it means to feel this</p>
      <p>pain,</p>
      <p>this loss.</p>
      <p>And now I've lost you.</p>
      <PhotoSwipe id={'000010.2560856880'} />
    </div>

    <div
      style={{
        ...styles.backgroundImage,
        backgroundImage: `url("${assetUrl}/000010.2560856880.png")`
      }}
    />
  </React.Fragment>
);
export default Page09;
