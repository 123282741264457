import React from 'react';
import styles from './styles.js';
import PhotoSwipe from '../components/PhotoSwipe';

const Page04 = ({ assetUrl }) => (
  <React.Fragment>
    <div style={styles.copyBasier}>
      <p>
        I know how to build the world that’s out there for you, if I can just
        find you. If I can just find a way in.
      </p>
      <p>
        I know how to make a story, the story I can tell you. It’s the truth,
        all of it.
      </p>
      <p>It’s the end of everything.</p>
      <p>We're alone</p>
      <PhotoSwipe id={'000032.2889990322'} width={1024} height={768} />
    </div>
    <div
      style={{
        ...styles.backgroundImage,
        backgroundImage: `url("${assetUrl}/000032.2889990322.png")`
      }}
    />
  </React.Fragment>
);
export default Page04;
